import React from 'react';
import './App.css';
import { BrowserRouter as Router, Link, Route, Routes } from 'react-router-dom';
import TakePhoto from './pages/takePhoto/takePhoto';
import ListPhotos from './pages/listPhotos/listPhotos';
import AuthContextProvider from './components/requireAuth';
import AdminPage from './pages/admin/admin';
import HomePage from './pages/home/homePage';
import RsvpPage from './pages/rsvp/rsvpPage';
import OurStoryPage from './pages/ourStory/ourStoryPage';
import AccomodationsPage from './pages/accomodations/accomodationsPage';
import VenueSchedulePage from './pages/venueSchedule/venueSchedulePage';
import RegistryPage from './pages/registry/registryPage';

function App() {
  return (
    <div className="App">
      <AuthContextProvider>
        <Router>
          <Routes>
            <Route path="/" element={<HomePage/>}/>
            <Route path="/our-story" element={<OurStoryPage/>}/>
            <Route path="/accomodations" element={<AccomodationsPage/>}/>
            <Route path="/venue-schedule" element={<VenueSchedulePage/>}/>
            <Route path="/registry" element={<RegistryPage/>}/>
            <Route path="/rsvp" element={<RsvpPage/>}/>
            <Route path="/photos" element={<ListPhotos/>}/>
            <Route path="/take-photo" element={<TakePhoto/>}/>
            <Route path="/admin" element={<AdminPage/>}/>
          </Routes>
        </Router>
      </AuthContextProvider>
    </div>
  );
}

export default App;
