import React from 'react';
import { Link } from 'react-router-dom';
import Divider from '../../components/divider/divider';
import Header from '../../components/header/header';
import { RequireUserOrInvitationAuth, useAuthContext } from '../../components/requireAuth';
import RsvpCard from '../../components/rsvp/rsvpCard';
import { WEDDING_DATE } from '../../constants';
import './homePage.css';

function HomePage() {
  const {username, invitationName} = useAuthContext();

  return (
    <RequireUserOrInvitationAuth>
      <div className="home-page">
        <div className="scroll-container">
          <div className="content">
            <Header/>
            <Divider/>
            <div className="big-picture">
              <div className="text-container">
                <div className="title">We're Getting Married!</div>
                <div className="date">{WEDDING_DATE}</div>
                {invitationName && (
                  <Link to="/rsvp" className="rsvp-link">RSVP</Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </RequireUserOrInvitationAuth>
  );
}

export default HomePage;
