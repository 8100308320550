import React from 'react';
import Divider from '../../components/divider/divider';
import Header from '../../components/header/header';
import { RequireUserOrInvitationAuth } from '../../components/requireAuth';
import './accomodationsPage.css';

function AccomodationsPage() {
  return (
    <RequireUserOrInvitationAuth>
      <div>
        <Header/>
        <Divider/>
        <div className="accomodations-page page-default">
          <h1>Accomodations</h1>
          <p>
            We have booked a block of hotel rooms with Hilton Sacramento Arden West.
            To secure a room, you must book by <b>Thursday, October 26, 2023</b>.
          </p>
          <p>
            To book online, use the link <a href="https://tinyurl.com/mrxp6hxm" target="_blank">https://tinyurl.com/mrxp6hxm</a>.
          </p>
          <p>
            To book by phone, call the hotel at <a href="tel:9169224700">(916) 922-4700</a> and mention group code <b>929</b>.
          </p>
          <hr/>
          <p>
            Before or after the big day, we encourage you to check out some of our favorite things to do in Sacramento!
          </p>
          <h3>Sights & Activities</h3>
          <div className="activity">
            <img className="image" src="https://forgotten-modern-assets.sfo3.cdn.digitaloceanspaces.com/images/stringio.jpg"/>
            <div className="text">
              <h4>The Crocker Art Museum</h4>
              <p>
                Several floors of a wide variety of historical and contemporary art, there's something for everyone to love.
              </p>
              <p>
                <a className="link" href="https://www.crockerart.org" target="_blank">Visit Website</a>
                <a className="link" href="https://www.google.com/maps/dir/?api=1&destination=Crocker+Art+Museum+Sacramento+CA" target="_blank">Navigate</a>
              </p>
            </div>
          </div>
          <div className="activity right">
            <img className="image" src="https://forgotten-modern-assets.sfo3.cdn.digitaloceanspaces.com/images/GettyImages-474730860_scaled.jpg"/>
            <div className="text">
              <h4>Old Town Sacramento</h4>
              <p>
                It's like walking into a western movie, the historical buildings are filled with a variety of shops next to the Sacramento River.
              </p>
              <p>
                <a className="link" href="https://www.oldsacramento.com/" target="_blank">Visit Website</a>
                <a className="link" href="https://www.google.com/maps/dir/?api=1&destination=Old+Town+Sacramento" target="_blank">Navigate</a>
              </p>
            </div>
          </div>
          <hr/>
          <h3>Drink & Food</h3>

          <div className="activity">
            <img className="image" src="https://forgotten-modern-assets.sfo3.cdn.digitaloceanspaces.com/images/IMG_20190811_120505_scaled.jpg"/>
            <div className="text">
              <h4>Queen Sheba Ethiopian Cuisine</h4>
              <p>
                Delicious family style plates of ethiopian food one eats with their hands
              </p>
              <p>
                <a className="link" href="https://www.queenshebas.com/_files/ugd/c52787_bd1ed078265f4479b80b1c15316eaf94.pdf" target="_blank">Menu</a>
                <a className="link" href="https://www.google.com/maps/dir/?api=1&destination=Queen+Sheba+Ethiopian+Cuisine+Sacramento+CA" target="_blank">Navigate</a>
              </p>
            </div>
          </div>
          <div className="activity right">
            <img className="image" src="https://forgotten-modern-assets.sfo3.cdn.digitaloceanspaces.com/images/FOB-cafe_shopify_1080x_scaled.jpg"/>
            <div className="text">
              <h4>Temple Coffee</h4>
              <p>
                The coffee here is made with a lot of care, and comes out delicious. The vibes are cool, too.
              </p>
              <p>
                <a className="link" href="https://templecoffee.com/" target="_blank">Visit Website</a>
                <a className="link" href="https://www.google.com/maps/dir/?api=1&destination=Temple+Coffee+Roasters+2600+Fair+Oaks+Blvd+Sacramento+CA" target="_blank">Navigate</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </RequireUserOrInvitationAuth>
  );
}

export default AccomodationsPage;
