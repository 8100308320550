import React from 'react';
import Divider from '../../components/divider/divider';
import Header from '../../components/header/header';
import { RequireUserOrInvitationAuth } from '../../components/requireAuth';
import './ourStoryPage.css';

function OurStoryPage() {
  return (
    <RequireUserOrInvitationAuth>
      <div>
        <Header/>
        <Divider/>
        <div className="our-story-page page-default">
          <h1>Our Story</h1>
          <img src="https://forgotten-modern-assets.sfo3.cdn.digitaloceanspaces.com/images/2966227554.jpg" className="our-story-img"/>
          <p>
            Shay and Hanna met in November 2019 over sushi. Hanna loved that Shay tried the ume shisho (a very sour and salty pickled plum that is an acquired taste). Shay loved that Hanna said she liked to craft and make things. 
          </p>
          <p>
            Their romance blossomed even throughout covid guidelines and closures, and moved in together informally, staying in Running Springs for several months. In November of 2020, they took a trip to Chico, CA together. Hanna was considering relocating, but after visiting with Shay knew she couldn't move if he wasn't there too. 
          </p>
          <p>
            They made a choice to move in together (formally this time) and fell in love with Davis. In January of 2022, they adopted their dog, Ladybug. 
          </p>
          <p>
            To mark their three year anniversary, the couple planned a return trip to Chico in November 2022. Shay proposed in Bidwell park, and Hanna said yes! 
          </p>
          <p>
            What's your favorite memory of the couple? 
          </p>
        </div>
      </div>
    </RequireUserOrInvitationAuth>
  );
}

export default OurStoryPage;
