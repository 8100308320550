import React from 'react';
import Divider from '../../components/divider/divider';
import Header from '../../components/header/header';
import { RequireUserOrInvitationAuth } from '../../components/requireAuth';
import RsvpCard from '../../components/rsvp/rsvpCard';
import './rsvpPage.css';

function RsvpPage() {
  return (
    <RequireUserOrInvitationAuth>
      <div className="rsvp-page">
        <Header/>
        <Divider/>
        <RsvpCard/>
      </div>
    </RequireUserOrInvitationAuth>
  )
}

export default RsvpPage;
