import React, { createRef, FormEvent, useState } from 'react';
import { API_BASE } from '../../constants';
import { useAuthContext } from '../requireAuth';
import './login.css';

function Login() {
  const {refresh} = useAuthContext();

  const usernameRef = createRef<HTMLInputElement>();
  const passwordRef = createRef<HTMLInputElement>();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const onUsernameInput = () => usernameRef.current && setUsername(usernameRef.current.value);
  const onPasswordInput = () => passwordRef.current && setPassword(passwordRef.current.value);

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();

    const go = async () => {
      try {
        const response = await fetch(`${API_BASE}/wedding/api/auth/login`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ username, password })
        });

        if (response.ok) {
          console.log('headers', response.headers);
          const token = response.headers.get('X-Api-Token');
          window.localStorage.setItem('sh-wedding.token', token ?? '');
          refresh();
        } else {
          alert('Bad login');
        }
      } catch (e: any) {
        console.log('error', e);
        alert('Unknown error');
      }
    };

    void go();
  };

  return (
    <form onSubmit={onSubmit} className="login-form">
      <h3>Login</h3>
      <div>
        <input ref={usernameRef} type="text" placeholder="Username" value={username} onInput={onUsernameInput}/>
      </div>
      <div>
        <input ref={passwordRef} type="password" placeholder="Password" value={password} onInput={onPasswordInput}/>
      </div>
      <div>
        <input type="submit" value="Submit"/>
      </div>
    </form>
  );
}

export default Login;
