import React, { useCallback, useEffect, useState } from 'react';
import { PhotoDto } from '../takePhoto/takePhoto';
import Lightbox, { ImagesListType } from 'react-spring-lightbox';
import './listPhotos.css';
import { API_BASE } from '../../constants';
import { Link } from 'react-router-dom';

function ListPhotos() {
  const [photos, setPhotos] = useState<PhotoDto[]>([]);

  const [lightboxOpen, setLightboxOpen] = useState<boolean>(false);
  const [currentImageIndex, setCurrentIndex] = useState(0);

  const gotoPrevious = () =>
      currentImageIndex > 0 && setCurrentIndex(currentImageIndex - 1);

  const gotoNext = () =>
      currentImageIndex + 1 < photos.length && setCurrentIndex(currentImageIndex + 1);

  const doFetch = useCallback(
    () => {
      const go = async () => {
        try {
          const response = await fetch(`${API_BASE}/photobooth/api/photos`, {
            method: 'GET',
            headers: {
              'Accept': 'application/json'
            }
          });

          const json = await response.json() as PhotoDto[];

          setPhotos(json);
        } catch (e: any) {
          console.log('error', e);
        }
      };

      void go();
    },
    []
  );

  useEffect(doFetch, [doFetch]);

  const handleClick = (index: number) => {
    setCurrentIndex(index);
    setLightboxOpen(true);
  };

  return (
    <div>
      <div className="photos-header">
        <Link to="/" className="link">Home</Link>
        {` `}
        <Link to="/take-photo" className="link">Take a Photo</Link>
      </div>
      {!lightboxOpen && (
        <div className="grid">
          {photos.map((photo, index) => (
            <img src={photo.url} onClick={() => handleClick(index)}/>
          ))}
        </div>
      )}
      <Lightbox
        isOpen={lightboxOpen}
        onClose={() => setLightboxOpen(false)}
        onPrev={gotoPrevious}
        onNext={gotoNext}
        images={photos.map((photo) => ({
          src: photo.url,
          loading: 'lazy',
          alt: ''
        }))}
        currentIndex={currentImageIndex}
      />
    </div>
  );
}

export default ListPhotos;
function userEffect() {
  throw new Error('Function not implemented.');
}

