import React, { createRef, FormEvent, useCallback, useEffect, useState } from 'react';
import { fetchFromApi } from '../../../constants';
import './adminMeals.css';

export type Meal = {
  id: string
  name: string
  description: string
};

function AdminMeals() {
  const [meals, setMeals] = useState<Meal[]>([]);

  const [editingId, setEditingId] = useState<string>();

  const refresh = useCallback(
    async () => {
      const response = await fetchFromApi<Meal[]>('/wedding/api/meals', 'GET');

      if (response instanceof Error) {
        alert(response.message);
      } else {
        setMeals(response);
        setEditingId(undefined);
      }
    },
    []
  );

  const doDelete = useCallback(
    async (id: string) => {
      const response = await fetchFromApi<null>(`/wedding/api/meals/${encodeURIComponent(id)}/delete`, 'DELETE');

      if (response instanceof Error) {
        alert(response.message);
      } else {
        refresh();
      }
    },
    []
  );

  useEffect(() => void refresh(), []);

  return (
    <div>
      <h2>Meals</h2>
      {meals.length === 0 ? (
        <p>No meals</p>
      ) : (
        meals.map((meal) =>
          (editingId === meal.id) ? (
            <AddEdit key={meal.id} meal={meal} onRefresh={refresh} onCancel={() => setEditingId(undefined)}/>
          ) : (
            <div key={meal.id} className="meal-list-item">
              <strong>{meal.name}</strong>
              {meal.description.split('\n').map((p) => <p>{p}</p>)}
              <button onClick={() => setEditingId(meal.id)}>Edit</button>
              <button onClick={() => doDelete(meal.id)}>Delete</button>
            </div>
          )
        )
      )}
      <AddEdit onRefresh={refresh} onCancel={() => {}}/>
    </div>
  );
}

type AddEditProps = {
  meal?: Meal,
  onRefresh: () => void
  onCancel: () => void
};

function AddEdit({meal, onRefresh, onCancel}: AddEditProps) {
  const nameRef = createRef<HTMLInputElement>();
  const descriptionRef = createRef<HTMLTextAreaElement>();

  const [name, setName] = useState<string>(meal?.name ?? '');
  const [description, setDescription] = useState<string>(meal?.description ?? '');

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault();

    const response = await fetchFromApi(`/wedding/api/meals/${meal ? `${meal.id}/update` : 'create'}`, 'POST', {
      name,
      description
    });

    if (response instanceof Error) {
      alert(response.message);
    } else {
      setName('');
      setDescription('');
      onRefresh();
    }
  };

  return (
    <form onSubmit={onSubmit} className="add-edit-meal">
      {!meal && <h3>Add meal</h3>}
      <div className="add-edit-meal-form-fields">
        <input
          className="meal-name-input"
          type="text"
          ref={nameRef}
          value={name}
          placeholder="Name"
          onInput={() => nameRef.current && setName(nameRef.current.value)}
        />
        <textarea
          className="meal-description-input"
          ref={descriptionRef}
          value={description}
          placeholder="Description"
          onInput={() => descriptionRef.current && setDescription(descriptionRef.current.value)}
        >
          {description}
        </textarea>
        <input type="submit" value="Save"/>
        {meal && <button onClick={onCancel}>Cancel</button>}
      </div>
    </form>
  );
}

export default AdminMeals;
