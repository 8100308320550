import React from 'react';
import Divider from '../../components/divider/divider';
import Header from '../../components/header/header';
import { RequireUserOrInvitationAuth } from '../../components/requireAuth';
import './registryPage.css';

function RegistryPage() {
  return (
    <RequireUserOrInvitationAuth>
      <div>
        <Header/>
        <Divider/>
        <div className="registry-page page-default">
          <p className="registry-header">
            Your presence at our wedding is the greatest gift of all.
          </p>
          <p>
            However, should you wish to give us a gift, we have created a registry with MyRegistry to make this more convenient.
          </p>
          <a className="link" href="https://www.myregistry.com/wedding-registry/hanna-nelson-and-shay-mathews-davis-ca/3638084" target="_blank">
            View Our Registry
          </a>
        </div>
      </div>
    </RequireUserOrInvitationAuth>
  );
}

export default RegistryPage;
