import React, { RefObject, createRef, useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Webcam from 'react-webcam';
import cameraIcon from '../../assets/images/camera.png';
import timerIcon from '../../assets/images/timer.png';
import { API_BASE } from '../../constants';
import './takePhoto.css';

export type PhotoDto = {
  id: string,
  url: string,
  takenOn: number,
  description: string
};

function b64toBlob(b64Data: string, contentType: string = 'image/jpeg', sliceSize: number = 512) {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, {type: contentType});
  return blob;
}

function imageToBlob(b64Image: string) {
  // var ImageURL = document.getElementById(ImgId).getAttribute('src');
  // Split the base64 string in data and contentType
  const block = b64Image.split(";");
  // Get the content type of the image
  const contentType = block[0].split(":")[1];// In this case "image/gif"
  // get the real base64 content of the file
  const realData = block[1].split(",")[1];// In this case "R0lGODlhPQBEAPeoAJosM...."

  // Convert it to a blob to upload
  return b64toBlob(realData, contentType);
}

function TakePhoto() {
  const webcamRef = useRef<Webcam>() as RefObject<Webcam>;

  const [loading, setLoading] = useState<boolean>(false);
  const [lastPhoto, setLastPhoto] = useState<PhotoDto>();

  const [countdown, setCountdown] = useState<number | null>(null);

  const [mediaDevices, setMediaDevices] = useState<MediaDeviceInfo[]>([]);
  const [selectedMediaDeviceId, setSelectedMediaDeviceId] = useState<string>();
  const mediaDeviceRef = createRef<HTMLSelectElement>();

  const size = useWindowSize();
  const isLandscape = size.height <= size.width;
  const ratio = isLandscape ? size.width / size.height : size.height / size.width;
  console.log(ratio);

  useEffect(() => {
    const go = async () => {
      const mediaDevices = await window.navigator.mediaDevices.enumerateDevices();
      setMediaDevices(mediaDevices);

      if (mediaDevices.length > 0) {
        setSelectedMediaDeviceId(mediaDevices[0].deviceId);
      }

      window.scrollTo(0, 1);
    };

    void go();
  }, []);

  const takeScreenshot = useCallback(
    () => {
      if (loading) {
        return;
      }

      const screenshot = webcamRef.current?.getScreenshot();
      if (!screenshot) {
        return;
      }

      const send = async () => {
        setLoading(true);

        const formData = new FormData();
        formData.append('image', imageToBlob(screenshot));
        formData.append('description', 'todo');

        try {
          const response = await fetch(`${API_BASE}/photobooth/api/photos/upload`, {
            method: 'POST',
            body: formData
          });
          const json = await response.json() as PhotoDto;

          console.log('success', json);

          setLastPhoto(json);
        } catch (e: any) {
          console.log('error', e);
        }

        setLoading(false);
      };

      void send();

    },
    [webcamRef.current, loading]
  );

  useEffect(() => {
    if (countdown === null) {
      return;
    }

    if (countdown === 0) {
      takeScreenshot();
      setCountdown(null);
    } else {
      const id = setTimeout(() => setCountdown(countdown - 1), 1000);

      return () => clearTimeout(id);
    }
  }, [countdown, takeScreenshot]);

  const onMediaDevice = (value: string) => {
    const device = mediaDevices.find((d) => d.deviceId === value);
    device && setSelectedMediaDeviceId(value);
  };

  return (
    <div>
      <div className="top-controls">
        <div className="see-photos-header">
          <Link to="/" className="link">Home</Link>
          {` `}
          <Link to="/photos" className="link">See All Photos</Link>
        </div>
        <div className="media-device-select">
          <select
            ref={mediaDeviceRef}
            onChange={ () => mediaDeviceRef.current && onMediaDevice(mediaDeviceRef.current.value) }
          >
            {mediaDevices.map((device, index) => (
              <option value={device.deviceId}>
                {device.label || `Device ${index + 1}`}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div>
        <Webcam
          ref={webcamRef}
          className="webcam"
          audio={false}
          screenshotFormat="image/jpeg"
          // height={576}
          // width={1024}
          height={size.height - 100}
          width={size.width - 50}
          minScreenshotHeight={Math.max(size.height, 720)}
          minScreenshotWidth={Math.max(size.width, 1280)}
          {...(selectedMediaDeviceId ? {
            videoConstraints: {
              // aspectRatio: ratio,
              // width: size.width,
              // height: size.height,
              deviceId: selectedMediaDeviceId
            }
          } : {})}
        />
      </div>
      <div className="buttons">
        {countdown === null && (
          <button className="snapButton" onClick={takeScreenshot}>
            <img src={cameraIcon}/>
          </button>
        )}
        <button className="snapButton" onClick={() => setCountdown(10)}>
          {countdown === null ? <img src={timerIcon}/> : countdown}
        </button>
      </div>
      {lastPhoto && (
        <div className="photoResult">
          <div className="closeResult" onClick={() => setLastPhoto(undefined)}/>
          <div className="modal">
            <h2>Your Photo</h2>
            <p>View photos on your phone at: <b>{window.location.origin}/photos</b></p>
            <button className="closeButton" onClick={() => setLastPhoto(undefined)}>Close</button>
            <img src={lastPhoto.url} className="last-photo"/>
          </div>
        </div>
      )}
    </div>
  );
}

type WindowSize = {
  width: number
  height: number
};

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}

export default TakePhoto;
