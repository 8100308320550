import React from 'react';
import Divider from '../../components/divider/divider';
import Header from '../../components/header/header';
import { RequireUserOrInvitationAuth } from '../../components/requireAuth';
import { WEDDING_DATE, WEDDING_START_TIME } from '../../constants';
import './venuSchedulePage.css';

function VenueSchedulePage() {
  return (
    <RequireUserOrInvitationAuth>
      <div className="venue-schedule-page">
        <Header/>
        <Divider/>
        <div className="page-default">
          <h2>Venue</h2>
          <p>
            We are excited to celebrate with you at our home at
          </p>
          <p className="address">{WEDDING_START_TIME} on {WEDDING_DATE}</p>
          <p className="address">
            4820 Engle Rd<br/>
            Carmichael, CA 95608
          </p>
          <p>
            While some street parking is available, we encourage all our guests to use a taxi or rideshare so they can imbibe responsibly.
          </p>
          <h2>Schedule of Events</h2>
          <table className="schedule-of-events-table">
            <tbody>
              <tr>
                <th>3:00</th>
                <td>Welcome, Cocktails, & Appetizers</td>
              </tr>
              <tr>
                <th>4:00</th>
                <td>Group Photo & Newlywed Trivia</td>
              </tr>
              <tr>
                <th>4:45</th>
                <td>Get Dinner</td>
              </tr>
              <tr>
                <th>5:00</th>
                <td>Toasts & Speeches</td>
              </tr>
              <tr>
                <th>6:00</th>
                <td>First Dance & Dancing!</td>
              </tr>
              <tr>
                <th>9:30</th>
                <td>Our neighborhood quiet hours begin, so the party will be winding down</td>
              </tr>
            </tbody>
          </table>
          <h2>Attire</h2>
          <p>
            Please wear comfortable dancing shoes and smart casual clothing. Sacramento in November can range from 40-70℉.
          </p>
          </div>
      </div>
    </RequireUserOrInvitationAuth>
  );
}

export default VenueSchedulePage;
