import React, { createRef, FormEvent, useEffect, useState } from 'react';
import { API_BASE, fetchFromApi } from '../../constants';
import { useAuthContext } from '../requireAuth';
import './invitationLogin.css';

function InvitationLogin() {
  const {refresh} = useAuthContext();

  const codeRef = createRef<HTMLInputElement>();

  const [invitationCode, setInvitationCode] = useState<string>('');

  useEffect(() => {
    codeRef.current && codeRef.current.focus();
    document.body.classList.add('dark');

    return () => {
      document.body.classList.remove('dark');
    };
  }, []);

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (invitationCode.trim().length > 0) {
      try {
        const response = await fetch(`${API_BASE}/wedding/api/auth/invitation-login`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ code: invitationCode })
        });

        if (response.ok) {
          console.log('headers', response.headers);
          const token = response.headers.get('X-Api-Token');
          window.localStorage.setItem('sh-wedding.token', token ?? '');
          refresh();
        } else {
          alert('Sorry, we don\'t recognize that code.');
        }
      } catch (e: any) {
        console.log('error', e);
        alert('Unknown error');
      }
    }
  };

  return (
    <div className="invitation-login">
      <div className="shay-hanna">
        {/* <img
          className="image-left"
          src="https://forgotten-modern-assets.sfo3.cdn.digitaloceanspaces.com/images/leaves-floral-bloom-vector-svg-Graphics-17405598-1.png"
        />
        <img
          className="image-right"
          src="https://forgotten-modern-assets.sfo3.cdn.digitaloceanspaces.com/images/leaves-floral-bloom-vector-svg-Graphics-17405598-1.png"
        /> */}
        <div className="text">
          Shay Mathews
          <div>&</div>
          Hanna Nelson
        </div>
      </div>
      <h1>Welcome!</h1>
      <p>Please enter the three digit code that was written on your invitation.</p>
      <form onSubmit={onSubmit} className="form">
        <input
          ref={codeRef}
          type="text"
          pattern="\d*"
          placeholder="123"
          maxLength={3}
          value={invitationCode}
          onInput={() => codeRef.current && setInvitationCode(codeRef.current.value)}
        />
        <input
          type="submit"
          value="Submit"
        />
      </form>
    </div>
  );
}

export default InvitationLogin;
