import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useAuthContext } from '../requireAuth';
import './header.css';
import { API_BASE } from '../../constants';

function Header() {
  const {username, invitationName, refresh} = useAuthContext();

  const linkClassName = ({isActive}: {isActive: boolean}) => isActive ? 'active' : '';

  const logout = () => {
    if (!username) {
      return;
    }

    const go = async () => {
      try {
        await fetch(`${API_BASE}/wedding/api/auth/logout`, {method: 'POST'});
      } catch (e) {
        console.log(e);
      }

      window.localStorage.removeItem('sh-wedding.token');
    void refresh();
    };

    void go();
  };

  return (
    <div className="header">
      <div className="title">
        <Link to="/">
          Shay<span className="and">&</span>Hanna
        </Link>
      </div>
      <div className="links">
        <NavLink to="/our-story" className={linkClassName}>Our Story</NavLink>
        <NavLink to="/accomodations" className={linkClassName}>Accomadations</NavLink>
        <NavLink to="/venue-schedule" className={linkClassName}>Venue & Schedule</NavLink>
        <NavLink to="/registry" className={linkClassName}>Registry</NavLink>
        {invitationName && <NavLink to="/rsvp" className={linkClassName}>RSVP</NavLink>}
        {username && <NavLink to="/admin" className={linkClassName}>Admin</NavLink>}
        {username && <button onClick={logout}>Logout</button>}
      </div>
    </div>
  );
}

export default Header;
