export const API_BASE = `https://${window.location.hostname}`;

export const WEDDING_DATE = 'Saturday, November 11th, 2023';
export const WEDDING_START_TIME = '3:00pm';

type Method = 'GET' | 'PUT' | 'POST' | 'DELETE';

export const fetchFromApi = async <T> (path: string, method: Method, jsonBody?: any): Promise<T | Error> => {
  const options = (() => {
    const headers: Record<string, string> = {
      'X-Api-Token': window.localStorage.getItem('sh-wedding.token') ?? ''
    };
  
    const options: RequestInit = {
      method
    };
  
    if (jsonBody) {
      headers['Content-Type'] = 'application/json';
      options.body = JSON.stringify(jsonBody);
    }
  
    options.headers = headers;
  
    return options;
  })();

  try {
    const response = await fetch(`${API_BASE}${path}`, options);

    const isJson = response.headers.get('Content-Type')?.includes('application/json');
    const data = await (isJson ? response.json() : response.text());

    if (response.ok) {
      return data as T;
    } else {
      return new Error(`Non OK response from ${path}: ${data}`);
    }
  } catch (e: any) {
    if (e instanceof Error) {
      return e;
    } else {
      return Error(`Unknown error fetching from ${path}`, e);
    }
  }
};
