import React from 'react';
import './divider.css';

function Divider() {
  return (
    <div className="divider div-transparent div-arrow-down"></div>
  );
}

export default Divider;
