import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { API_BASE, fetchFromApi } from '../constants';
import InvitationLogin from './invitationLogin/invitationLogin';
import Login from './login/login';

export type GetLoginresponse = {
  username?: string
  invitationCode?: string
  invitationName?: string
};

export type AuthContextData = {
  username: string
  invitationName: string
  refresh: () => Promise<void>
};

const AuthContext = React.createContext<AuthContextData>({
  username: '',
  invitationName: '',
  refresh: async () => {}
});

export type Props = {
  children: JSX.Element
};

function AuthContextProvider({ children }: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [username, setUsername] = useState<string>('');
  const [invitationName, setInvitationName] = useState<string>('');

  const refresh = useCallback(
    async () => {
      setIsLoading(true);

      try {
        const response = await fetchFromApi<GetLoginresponse>('/wedding/api/auth', 'GET');

        if (response instanceof Error) {
          // alert(response.message);
          setUsername('');
          setInvitationName('');
        } else {
          setUsername(response.username ?? '');
          setInvitationName(response?.invitationName ?? '');
        }
      } catch (e: any) {
        console.log('error', e);
        setUsername('');
        setInvitationName('');
      }

      setIsLoading(false);
    },
    []
  );

  useEffect(() => {
    void refresh();
  }, []);

  const data: AuthContextData = useMemo(() => ({
    username, invitationName, refresh
  }), [username, invitationName]);

  return (
    <AuthContext.Provider value={data}>
      {!isLoading && children}
    </AuthContext.Provider>
  )
}

export default AuthContextProvider;

export const useAuthContext = () => useContext(AuthContext);

export const RequireUserAuth: React.FC<Props> = ({ children }: Props) => {
  const { username } = useAuthContext();

  if (!username) {
    return <Login/>;
  }

  return children;
};

export const RequireInvitationAuth: React.FC<Props> = ({ children }: Props) => {
  const { invitationName } = useAuthContext();

  if (!invitationName) {
    return <InvitationLogin/>;
  }

  return children;
};

export const RequireUserOrInvitationAuth: React.FC<Props> = ({ children }: Props) => {
  const { username, invitationName } = useAuthContext();

  if (!username && !invitationName) {
    return <InvitationLogin/>;
  }

  return children;
};
