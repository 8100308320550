import React, { createRef, FormEvent, useCallback, useEffect, useState } from 'react';
import { fetchFromApi } from '../../constants';
import { Invitation, Rsvp } from '../admin/invitations/adminInvitations';
import { Meal } from '../admin/meals/adminMeals';
import { useAuthContext } from '../requireAuth';
import './rsvpCard.css';

function RsvpCard() {
  const {username, invitationName} = useAuthContext();

  const songRequestRef = createRef<HTMLInputElement>();

  const [invitation, setInvitation] = useState<Invitation>();
  const [rsvp, setRsvp] = useState<Rsvp>();
  const [meals, setMeals] = useState<Meal[]>([]);

  const [saving, setSaving] = useState<boolean>(false);
  const [showSaved, setShowSaved] = useState<boolean>(false);

  useEffect(() => {
    const go = async () => {
      if (invitationName) {
        const [invitationResponse, rsvpResponse, mealsResponse] = await Promise.all([
          fetchFromApi<Invitation>('/wedding/api/invitations/my-invitation', 'GET'),
          fetchFromApi<Rsvp>('/wedding/api/rsvp/my-rsvp', 'GET'),
          fetchFromApi<Meal[]>('/wedding/api/meals', 'GET')
        ]);

        if (invitationResponse instanceof Error) {
          // TODO
          setInvitation(undefined);
        } else {
          setInvitation(invitationResponse);
        }

        if (rsvpResponse instanceof Error) {
          // TODO
          setRsvp(undefined);
        } else {
          setRsvp(rsvpResponse);
        }

        if (mealsResponse instanceof Error) {
          // TODO
          setMeals([]);
        } else {
          setMeals(mealsResponse);
        }
      }
    };

    void go();
  }, [username, invitationName]);

  useEffect(() => {
    if (showSaved) {
      const id = setTimeout(() => setShowSaved(false), 2000);
    }
  }, [showSaved]);

  const saveRsvp = useCallback(
    async (newRsvp: Rsvp) => {
      setSaving(true);

      const response = await fetchFromApi<Rsvp>('/wedding/api/rsvp/reserve', 'POST', {
        attending: newRsvp.attending,
        count: newRsvp.count,
        mealIds: newRsvp.mealIds,
        songRequest: newRsvp.songRequest
      });
  
      if (response instanceof Error) {
        // TODO
      } else {
        setRsvp(response);
        setSaving(false);
        setShowSaved(true);
      }
    },
    []
  );

  const onAttending = (attending: boolean) => {
    if (!invitation || !rsvp) {
      return;
    }

    const newCount = invitation.count === 1 ? 1 : rsvp.count;
    const newMealIds: string[] = [];

    for (let mealIndex = 0; mealIndex < newCount; mealIndex++) {
      newMealIds.push(rsvp.mealIds[mealIndex] || meals[0].id);
    }

    void saveRsvp({
      ...rsvp,
      count: newCount,
      mealIds: newMealIds,
      attending
    });
  };

  const onCount = (count: number) => {
    if (!invitation || !rsvp) {
      return;
    }

    const newMealIds: string[] = [];

    for (let mealIndex = 0; mealIndex < count; mealIndex++) {
      newMealIds.push(rsvp.mealIds[mealIndex] || meals[0].id);
    }

    void saveRsvp({
      ...rsvp,
      count,
      mealIds: newMealIds
    });
  };

  const onMealChange = (i: number, mealId: string) => {
    if (!invitation || !rsvp) {
      return;
    }

    const newMealIds: string[] = [];

    for (let mealIndex = 0; mealIndex < rsvp.count; mealIndex++) {
      if (mealIndex === i) {
        newMealIds.push(mealId);
      } else {
        newMealIds.push(rsvp.mealIds[mealIndex] || meals[0].id);
      }
    }

    void saveRsvp({
      ...rsvp,
      mealIds: newMealIds
    });
  };

  const onSubmitSongRequest = (event: FormEvent) => {
    event.preventDefault();

    if (!invitation || !rsvp) {
      return;
    }

    void saveRsvp({...rsvp});
  };

  const rsvpCountOptions: number[] = [];
  if (invitation) {
    for (let i = invitation.count; i > 0; i--) {
      rsvpCountOptions.push(i);
    }
  }

  const rsvpMealIndices: number[] = [];
  if (rsvp) {
    for (let i = 0; i < rsvp.count; i++) {
      rsvpMealIndices.push(i);
    }
  }

  return (
    <div className="rsvp-card">
      <div className="invitation-name">
        {invitation ? invitation.name : ''}
      </div>
      <p className="attend-question">
        Will you be able to attend?
      </p>
      <div className="attending-buttons">
        <button
          className={(rsvp && rsvp.responded) ? (rsvp.attending ? 'selected' : 'not-selected') : ''}
          onClick={() => onAttending(true)}
        >
          Looking forward to attending
        </button>
        <button
          className={(rsvp && rsvp.responded) ? (rsvp.attending ? 'not-selected' : 'selected') : ''}
          onClick={() => onAttending(false)}
        >
          Disappointed {invitation?.count === 1 ? 'I' : 'we'} cannot make it
        </button>
      </div>
      {(invitation && rsvp && rsvp.responded) && (
        rsvp.attending ? (
          <div>
            <p className="attend-question">
              Your invitation is for {invitation.count} {invitation.count === 1 ? 'guest' : 'guests'}.
              {invitation.count > 1 && ' How many will attend?'}
            </p>
            {invitation.count > 1 && (
              <div className="rsvp-count-buttons">
                {rsvpCountOptions.map((count) => (
                  <button
                    className={count === rsvp.count ? 'selected' : 'not-selected'}
                    onClick={() => onCount(count)}
                  >
                    {(count === invitation.count) ? (invitation.count === 2 ? 'Both of us' : `All ${count}`) : `Just ${count}`}
                  </button>
                ))}
              </div>
            )}
            <p className="meals-question">
              Please select which {rsvp.count === 1 ? 'meal' : 'meals'} you would like.
            </p>
            <table className="meals-table">
              <tbody>
                {rsvpMealIndices.map((i) => (
                  <tr key={i}>
                    <td>
                      <strong>Meal #{i + 1}</strong>
                    </td>
                    <td>
                      <MealSelect
                        index={i}
                        meals={meals}
                        rsvp={rsvp}
                        onChange={(mealId) => onMealChange(i, mealId)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {meals && (
              <div className="menu">
                <h3>Food Menu</h3>
                {meals.map((meal) => (
                  <div>
                    <strong>{meal.name}</strong>
                    <p>{meal.description}</p>
                  </div>
                ))}
              </div>
            )}
            <div>
              <form className="song-request-form" onSubmit={onSubmitSongRequest}>
                <label htmlFor="song-request">I promise to dance if this song plays...</label>
                <div className="form-body">
                  <input
                    ref={songRequestRef}
                    id="song-request"
                    type="text"
                    value={rsvp.songRequest}
                    placeholder="Song Request"
                    onInput={() => songRequestRef.current && setRsvp({
                      ...rsvp,
                      songRequest: songRequestRef.current.value
                    })}
                  />
                  <input type="submit" value="Save"/>
                </div>
              </form>
            </div>
          </div>
        ) : (
          <>
            <p className="not-attending-message">
              We're sorry you will not be able to attend our event and will surely feel your absence.
            </p>
            <p className="not-attending-message">
              Looking forward to sending you all the pictures.
            </p>
          </>
        )
      )}
      <div className="save-status">
        {(invitation && rsvp && rsvp.attending) && (
          saving ? 'Saving...' : showSaved ? 'Saved!' : <span>&nbsp;</span>
        )}
      </div>
    </div>
  );
}

type MealSelectProps = {
  index: number
  meals: Meal[]
  rsvp: Rsvp
  onChange: (mealId: string) => void
};

function MealSelect({index, meals, rsvp, onChange}: MealSelectProps) {
  const selectRef = createRef<HTMLSelectElement>();

  return (
    <select
      ref={selectRef}
      onChange={() => selectRef.current && onChange(selectRef.current.value)}
    >
      {meals.map((meal) => (
        <option
          value={meal.id}
          key={meal.id}
          {...(rsvp.mealIds[index] === meal.id ? {selected: true} : {})}
        >
          {meal.name}
        </option>
      ))}
    </select>
  );
}

export default RsvpCard;
