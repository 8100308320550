import React, { createRef, FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { fetchFromApi } from '../../../constants';
import { Meal } from '../meals/adminMeals';
import './adminInvitations.css';

export type Invitation = {
  code: string
  name: string
  count: number
};

export type Rsvp = {
  invitationCode: string
  responded: boolean
  attending: boolean
  count: number
  mealIds: string[],
  songRequest: string
};

function AdminInvitations() {
  const [invitations, setInvitations] = useState<Invitation[]>([]);
  const [rsvps, setRsvps] = useState<Rsvp[]>([]);
  const [meals, setMeals] = useState<Meal[]>([]);

  const [editingCode, setEditingCode] = useState<string>();

  const bulkCreateRef = createRef<HTMLTextAreaElement>();
  const [bulkCreateContent, setBulkCreateContent] = useState<string>('');

  const refresh = useCallback(
    async () => {
      const response = await fetchFromApi<Invitation[]>('/wedding/api/invitations', 'GET');

      if (response instanceof Error) {
        alert(response.message);
      } else {
        setInvitations(response);
        setEditingCode(undefined);
      }

      const rsvpResponse = await fetchFromApi<Rsvp[]>('/wedding/api/rsvp', 'GET');

      if (rsvpResponse instanceof Error) {
        alert(rsvpResponse.message);
      } else {
        setRsvps(rsvpResponse);
      }

      const mealResponse = await fetchFromApi<Meal[]>('/wedding/api/meals', 'GET');

      if (mealResponse instanceof Error) {
        alert(mealResponse.message);
      } else {
        setMeals(mealResponse);
      }
    },
    []
  );

  const doDelete = useCallback(
    async (code: string) => {
      const response = await fetchFromApi<null>(`/wedding/api/invitations/${encodeURIComponent(code)}/delete`, 'DELETE');

      if (response instanceof Error) {
        alert(response.message);
      } else {
        refresh();
      }
    },
    []
  );

  useEffect(() => void refresh(), []);

  const onBulkSubmit = async (event: FormEvent) => {
    event.preventDefault();

    const invites = bulkCreateContent.split('\n').map((name) => {
      return {
        name: name.trim().replaceAll('\t', ' '),
        count: 1
      };
    });

    if (invites.length > 0) {
      const response = await fetchFromApi('/wedding/api/invitations/create', 'POST', invites);

      if (response instanceof Error) {
        alert(response.message);
      } else {
        refresh();
        setBulkCreateContent('');
      }
    }
  };

  const totalGuests =
    (invitations.length === 0)
      ? 0
      : invitations.reduce((total, curr) => total + curr.count, 0);
  const totalRsvps =
    (rsvps.length === 0)
      ? 0
      : rsvps.filter(
        r => r.attending
      ).filter(
        r => invitations.find(i => i.code == r.invitationCode)!!
      ).reduce(
        (total, rsvp) => total + rsvp.count, 0
      );

  const mealCounts = useMemo(
    () => {
      const mealCounts: Record<string, number> = {};

      rsvps.filter((rsvp) => rsvp.attending).forEach((rsvp) => {
        rsvp.mealIds.forEach((mealId) => {
          const count = mealCounts[mealId] || 0;
          mealCounts[mealId] = (count + 1);
        });
      });

      return mealCounts;
    },
    [rsvps]
  );

  const renderRsvp = (invitation: Invitation) => {
    const rsvp = rsvps.find((rsvp) => rsvp.invitationCode === invitation.code);

    if (!rsvp) {
      return (
        <>
          <td>No response</td>
          <td></td>
        </>
      );
    }
    console.log('rsvp', rsvp)

    if (rsvp.attending) {
      return (
        <>
          <td><strong>{rsvp.count} attending</strong></td>
          <td>
            {meals.map((meal) => {
              const rsvpsForMeal = rsvp.mealIds.filter((mealId) => mealId === meal.id);

              return (rsvpsForMeal.length === 0) ? null : (
                <div>{rsvpsForMeal.length} x {meal.name}</div>
              );
            })}
            {(rsvp.songRequest?.trim().length > 0) && (
              <div>
                Song Request: {rsvp.songRequest}
              </div>
            )}
          </td>
        </>
      );
    }

    return (
      <>
        <td>Not attending</td>
        <td></td>
      </>
    );
  };

  return (
    <div>
      <h2>Invitations</h2>
      <p>{totalGuests} guests invited, {totalRsvps} RSVPs</p>
      {meals.map((meal) => (
        <div>{mealCounts[meal.id] || 0} x {meal.name}</div>
      ))}
      {invitations.length === 0 ? (
        <p>No invitations</p>
      ) : (
        <table className="invitations-table">
          <thead>
            <tr>
              <th>Name</th>
              <th># Guests</th>
              <th>Invite code</th>
              <th>&nbsp;</th>
              <th>&nbsp;</th>
              <th>&nbsp;</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {invitations.map((invitation) =>
              (editingCode === invitation.code) ? (
                <tr>
                  <td colSpan={7}>
                    <AddEdit invitation={invitation} onRefresh={refresh} onCancel={() => setEditingCode(undefined)}/>
                  </td>
                </tr>
              ) : (
                <tr key={invitation.code} className="invitation-list-item">
                  <td className="name-cell"><strong>{invitation.name}</strong></td>
                  <td><span>{invitation.count}</span></td>
                  <td><strong>{invitation.code}</strong></td>
                  <td><button onClick={() => setEditingCode(invitation.code)}>Edit</button></td>
                  <td><button onClick={() => doDelete(invitation.code)}>Delete</button></td>
                  {renderRsvp(invitation)}
                </tr>
              )
            )}
          </tbody>
        </table>
      )}
      <AddEdit onRefresh={refresh} onCancel={() => {}}/>
      <form onSubmit={onBulkSubmit}>
        <h3>Bulk create</h3>
        <p>Enter one name per line.</p>
        <textarea
          className="bulk-invite-input"
          ref={bulkCreateRef}
          value={bulkCreateContent}
          onInput={() => bulkCreateRef.current && setBulkCreateContent(bulkCreateRef.current.value)}
        >
          {bulkCreateContent}
        </textarea>
        <input type="submit" value="Submit"/>
      </form>
    </div>
  );
}

type AddEditProps = {
  invitation?: Invitation,
  onRefresh: () => void
  onCancel: () => void
};

function AddEdit({invitation, onRefresh, onCancel}: AddEditProps) {
  const nameRef = createRef<HTMLInputElement>();
  const countRef = createRef<HTMLInputElement>();

  const [name, setName] = useState<string>(invitation?.name ?? '');
  const [count, setCount] = useState<number>(invitation?.count ?? 1);

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault();

    const toSend = { name, count };

    const response = await fetchFromApi(
      `/wedding/api/invitations/${invitation ? `${invitation?.code}/update` : 'create'}`,
      'POST',
      invitation ? toSend : [toSend]
    );

    if (response instanceof Error) {
      alert(response.message);
    } else {
      setName('');
      setCount(1);
      onRefresh();
    }
  };

  return (
    <form onSubmit={onSubmit} className="add-edit-invitation">
      {!invitation && <h3>Create invitation</h3>}
      <div className="add-edit-invitation-form-fields">
        <input
          className="invitation-name-input"
          type="text"
          ref={nameRef}
          value={name}
          placeholder="Name"
          onInput={() => nameRef.current && setName(nameRef.current.value)}
        />
        <strong>{count} guests</strong>
        <input
          className="invitation-count-input"
          type="range"
          ref={countRef}
          min="1"
          max="8"
          step="1"
          value={count}
          onChange={() => countRef.current && setCount(parseInt(countRef.current.value))}
        />
        <input type="submit" value="Save"/>
        {invitation && <button onClick={onCancel}>Cancel</button>}
      </div>
    </form>
  );
}

export default AdminInvitations;
