import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import AdminInvitations from '../../components/admin/invitations/adminInvitations';
import AdminMeals from '../../components/admin/meals/adminMeals';
import { RequireUserAuth, useAuthContext } from '../../components/requireAuth';
import './admin.css';
import { API_BASE } from '../../constants';

function AdminPage() {
  const {username, refresh} = useAuthContext();

  const [tab, setTab] = useState<'meals' | 'invitations'>('invitations');

  const logout = () => {
    const go = async () => {
      try {
        await fetch(`${API_BASE}/wedding/api/auth/logout`, {method: 'POST'});
      } catch (e) {
        console.log(e);
      }

      window.localStorage.removeItem('sh-wedding.token');
      void refresh();
    };

    void go();
  };

  return (
    <RequireUserAuth>
      <div className="admin-page">
        <h1>Admin</h1>
        <p>
          Hello, {username}. <button onClick={logout}>Logout</button>
        </p>
        <hr/>
        <div>
          Links:{' '}
          <Link to="/" className="link">Homepage</Link>{' '}
          <Link to="/photos" className="link">Photos</Link>{' '}
          <Link to="/take-photo" className="link">Take a photo</Link>
        </div>
        <hr/>
        <p>
          Manage:{' '}
          <button onClick={() => setTab('invitations')}>Invitations</button>
          <button onClick={() => setTab('meals')}>Meals</button>
        </p>
        <hr/>
        {tab === 'meals' && <AdminMeals/>}
        {tab === 'invitations' && <AdminInvitations/>}
      </div>
    </RequireUserAuth>
  );
}

export default AdminPage;
